<template>
  <div class="dryEyeTreatment" :class="isly?'isw90':''">
     <h3>干眼分析</h3>
    <div class="content">
      <div class="srnr">
        <h4>睑板腺</h4>
        <ul>
          <li></li>
          <li>睑缘及睑板腺开口</li>
          <li>上睑</li>
        </ul>
        <ul>
          <li>OD</li>
          <li> <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_Gap" placeholder="请选择">
            <el-option
                v-for="item in jybx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select></li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_Up" placeholder="请选择">
              <el-option
                  v-for="item in sj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_Gap" placeholder="请选择">
              <el-option
                  v-for="item in jybx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
             </el-select>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_Up" placeholder="请选择">
              <el-option
                  v-for="item in sj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>


        <ul style="padding-top: 20px;">
          <li></li>
          <li>下睑</li>
          <li>分泌物</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_Down" placeholder="请选择">
              <el-option
                  v-for="item in sj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_Secretion" placeholder="请选择">
              <el-option
                  v-for="item in fmw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_Down" placeholder="请选择">
              <el-option
                  v-for="item in sj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_Secretion" placeholder="请选择">
              <el-option
                  v-for="item in fmw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>

        <h4 style="padding-top: 20px">NIBUT</h4>
        <ul>
          <li></li>
          <li>首次破裂时间</li>
          <li>平均破裂时间</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_firTime')" v-model="postData.S_OP_Json.CL_RE_MG_OD_firTime" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_aveTime')" v-model="postData.S_OP_Json.CL_RE_MG_OD_aveTime" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_firTime')" v-model="postData.S_OP_Json.CL_RE_MG_OS_firTime" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_aveTime')" v-model="postData.S_OP_Json.CL_RE_MG_OS_aveTime" placeholder="请输入内容"></el-input>
          </li>
        </ul>


        <h4 style="padding-top: 20px">睑缘</h4>
        <ul>
          <li></li>
          <li>上脸缘</li>
          <li>下脸缘</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_marginUp')" v-model="postData.S_OP_Json.CL_RE_MG_OD_marginUp" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_marginDown')" v-model="postData.S_OP_Json.CL_RE_MG_OD_marginDown" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_marginUp')" v-model="postData.S_OP_Json.CL_RE_MG_OS_marginUp" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_marginDown')" v-model="postData.S_OP_Json.CL_RE_MG_OS_marginDown" placeholder="请输入内容"></el-input>
          </li>
        </ul>

      </div>


      <div class="srnr">
        <h4>脂质层</h4>
        <ul>
          <li></li>
          <li>描述</li>
          <li>结构</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_description" placeholder="请选择">
              <el-option
                  v-for="item in ms"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_structure" placeholder="请选择">
              <el-option
                  v-for="item in jg"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_description" placeholder="请选择">
              <el-option
                  v-for="item in ms"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_structure" placeholder="请选择">
              <el-option
                  v-for="item in jg"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>


        <ul style="padding-top: 20px;">
          <li></li>
          <li>分布情况</li>
          <li>色彩</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_distribution" placeholder="请选择">
              <el-option
                  v-for="item in fbqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_color" placeholder="请选择">
              <el-option
                  v-for="item in sc"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_distribution" placeholder="请选择">
              <el-option
                  v-for="item in fbqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_color" placeholder="请选择">
              <el-option
                  v-for="item in sc"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>


        <h4 style="padding-top: 20px">泪河高度</h4>
        <ul>
          <li></li>
          <li>描述</li>
          <li>高度</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_heightDes')" v-model="postData.S_OP_Json.CL_RE_MG_OD_heightDes" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_heightData')" v-model="postData.S_OP_Json.CL_RE_MG_OD_heightData" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_heightDes')" v-model="postData.S_OP_Json.CL_RE_MG_OS_heightDes" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_heightData')" v-model="postData.S_OP_Json.CL_RE_MG_OS_heightData" placeholder="请输入内容"></el-input>
          </li>
        </ul>


        <h4 style="padding-top: 20px">眼红</h4>
        <ul>
          <li></li>
          <li>结膜等级</li>
          <li>睫状等级</li>
        </ul>
        <ul>
          <li>OD</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_conjun')" v-model="postData.S_OP_Json.CL_RE_MG_OD_conjun" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OD_ciliary')" v-model="postData.S_OP_Json.CL_RE_MG_OD_ciliary" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul>
          <li>OS</li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_conjun')" v-model="postData.S_OP_Json.CL_RE_MG_OS_conjun" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <el-input @change="zh('CL_RE_MG_OS_ciliary')" v-model="postData.S_OP_Json.CL_RE_MG_OS_ciliary" placeholder="请输入内容"></el-input>
          </li>
        </ul>
      </div>


      <div class="srnr">
        <h4>眼表荧光染色</h4>
        <ul><li style="width: 8em;padding: 5px 10px;">分级</li></ul>
        <ul>
            <li style="width:  8em;padding: 5px 10px;">
            <el-select v-model="postData.S_OP_Json.CL_RE_MG_OD_grade" placeholder="请选择">
              <el-option
                  v-for="item in fj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul>
          <li style="width:  8em;padding: 5px 10px;">
          <el-select v-model="postData.S_OP_Json.CL_RE_MG_OS_grade" placeholder="请选择">
            <el-option
                v-for="item in fj"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li></ul>

        <ul style="padding-top: 20px; opacity: 0">
          <li style="width: 8em;padding: 5px 10px;">泪河高度</li>
        </ul>
        <ul style="opacity: 0">
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OD_heightData')" v-model="postData.S_OP_Json.CL_RE_MG_OD_heightData" disabled placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul style="opacity: 0">
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OS_heightData')" v-model="postData.S_OP_Json.CL_RE_MG_OS_heightData" disabled placeholder="请输入内容"></el-input>
          </li>
        </ul>

        <h4 style="padding-top: 20px">脸板腺分级</h4>
        <ul>
          <li style="width: 8em;padding: 5px 10px;">分级</li>
        </ul>
        <ul>
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OD_rank')" v-model="postData.S_OP_Json.CL_RE_MG_OD_rank" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul>
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OS_rank')" v-model="postData.S_OP_Json.CL_RE_MG_OS_rank" placeholder="请输入内容"></el-input>
          </li>
        </ul>

        <ul style="padding-top: 60px;">
          <li style="width: 8em;padding: 5px 10px;">分级2</li>
        </ul>
        <ul>
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OD_rank2')" v-model="postData.S_OP_Json.CL_RE_MG_OD_rank2" placeholder="请输入内容"></el-input>
          </li>
        </ul>
        <ul >
          <li style="width: 8em;padding: 5px 10px;">
            <el-input @change="zh('CL_RE_MG_OS_rank2')" v-model="postData.S_OP_Json.CL_RE_MG_OS_rank2" placeholder="请输入内容"></el-input>
          </li>
        </ul>
      </div>
    </div>





<!--    <UPfile :type="'MG'"></UPfile>-->

    <UPFILE :imgtype="'MG'" :datas.sync="dataL" :wjtype="'干眼分析'"></UPFILE>


    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.CL_RE_MG_Remark">
      </el-input>
    </div>

    <el-button type="primary" @click="save" v-if="isly">保存</el-button>

  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import {Loading} from "element-ui";
export default {
  name: "dryEyeTreatment",
  data() {
    return {
      val: '',
      postData: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId:this.$store.state.users.employees.ExpertId,
        S_OP_HealthCheckId:'',
        S_OP_ID:'',
        S_OP_Model:"CL_RE_MG220107",
        S_OP_Time: '',
        S_OP_Type:"CL_RE_MG",
        S_OP_XML:'',
        S_OP_Json: {
          CL_RE_MG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          CL_RE_MG_OD_Down:'',
          CL_RE_MG_OD_Gap:'',
          CL_RE_MG_OD_Secretion:'',
          CL_RE_MG_OD_Up:'',
          CL_RE_MG_OD_color:'',
          CL_RE_MG_OD_description:'',
          CL_RE_MG_OD_distribution:'',
          CL_RE_MG_OD_grade:'',
          CL_RE_MG_OD_structure:'',
          CL_RE_MG_OS_Down:'',
          CL_RE_MG_OS_Gap:'',
          CL_RE_MG_OS_Secretion:'',
          CL_RE_MG_OS_Up:'',
          CL_RE_MG_OS_color:'',
          CL_RE_MG_OS_description:'',
          CL_RE_MG_OS_distribution:'',
          CL_RE_MG_OS_grade:'',
          CL_RE_MG_OS_structure:'',
          CL_RE_MG_Remark:'',
          CL_RE_MG_isDone: true,
          CL_RE_MG_OD_firTime:'',
          CL_RE_MG_OS_firTime:'',
          CL_RE_MG_OD_aveTime :'',
          CL_RE_MG_OS_aveTime:'',
          CL_RE_MG_OD_heightDes:'',
          CL_RE_MG_OS_heightDes:'',
          CL_RE_MG_OD_heightData :'',
          CL_RE_MG_OS_heightData:'',
          CL_RE_MG_OD_rank :'',
          CL_RE_MG_OS_rank:'',
          CL_RE_MG_OD_marginUp  :'',
          CL_RE_MG_OS_marginUp:'',
          CL_RE_MG_OD_marginDown :'',
          CL_RE_MG_OS_marginDown:'',
          CL_RE_MG_OD_conjun:'',
          CL_RE_MG_OS_conjun:'',
          CL_RE_MG_OD_ciliary:'',
          CL_RE_MG_OS_ciliary:'',
          CL_RE_MG_OS_rank2: '',
          CL_RE_MG_OD_rank2: ''
        }
      },
      jybx: [
        {
          value: '未见异常',
          label: '未见异常'
        },
        {
          value: '睑缘不规则',
          label: '睑缘不规则'
        },
        {
          value: '睑缘充血',
          label: '睑缘充血'
        },
        {
          value: '睑板腺开口堵塞',
          label: '睑板腺开口堵塞'
        },
      ],
      sj: [
        {
          value: '无丢失',
          label: '无丢失'
        },
        {
          value: '小于1/3丢失',
          label: '小于1/3丢失'
        },
        {
          value: '1/3-2/3丢失',
          label: '1/3-2/3丢失'
        },
        {
          value: '大于2/3丢失',
          label: '大于2/3丢失'
        },
      ],
      ms: [
        {
          value: '厚',
          label: '厚'
        },
        {
          value: '正常',
          label: '正常'
        },
        {
          value: '薄',
          label: '薄'
        },
      ],
      jg: [
        {
          value: '清晰',
          label: '清晰'
        },
        {
          value: '不清晰',
          label: '不清晰'
        }
      ],
      fj: [
        {
          value: '0级',
          label: '0级'
        },
        {
          value: '1级',
          label: '1级'
        },
        {
          value: '2级',
          label: '2级'
        },
        {
          value: '3级',
          label: '3级'
        },
        {
          value: '',
          label: ''
        },
      ],
      fmw: [
        {
          value: '蛋清样',
          label: '蛋清样'
        },
        {
          value: '奶黄样',
          label: '奶黄样'
        },
        {
          value: '颗粒状',
          label: '颗粒状'
        },
        {
          value: '牙膏状',
          label: '牙膏状'
        },
      ],
      fbqk: [
        {
          value: '均匀',
          label: '均匀'
        },
        {
          value: '不均匀',
          label: '不均匀'
        },
      ],
      sc: [
        {
          value: '丰富',
          label: '丰富'
        },
        {
          value: '单一',
          label: '单一'
        },
      ],
      isly: false,
      dataL: {}
    }
  },

  watch: {
    dataL: {
      handler(n, o) {
        if (n) {
          // console.log(n)
          for (let key in n) {
            if (n[key]) this.postData.S_OP_Json[key] = n[key]
          }
        }
      }
    }
  },

  created() {
    // console.log(this.$route.query)
    if (this.$route.query.type) {this.isly = true}
    this._api.physicianVisits.getGyfx()
    .then(res => {
      // console.log(res)
      if (res.GetListResult && res.GetListResult.length >  0) {
        res.GetListResult[0].S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
        this.postData = JSON.parse(JSON.stringify(res.GetListResult[0]))
        // console.log(this.postData)
      }
    })
  },
  methods: {
    save() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      });
      let obj = JSON.parse(JSON.stringify(this.postData))
      obj.S_OP_Json = pf.conversionJson(obj.S_OP_Json)
      this._http.post(pf.getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1559227408,
          "sToken": "D35C30181D26439F92471835A4A98624"
        },
        o: obj
      })
      .then(res => {
        loadingInstance.close()
        if (res.Msg == "Success") {
          this.$store.commit('isbc/uptjk', {
            keys: 'gyfx',
            val: true
          })
           this.$alert('干眼分析保存成功！', '提示')
        }else {
          this.$alert('干眼分析保存失败！', '提示')
        }
        // console.log(res)
      })
    },

    zh(key) {
      this.postData.S_OP_Json[key] = this.postData.S_OP_Json[key] !== ''?this.postData.S_OP_Json[key].toString():this.postData.S_OP_Json[key]
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile'),
    UPFILE: () => import('../../components/sbData')
  }
}
</script>

<style scoped lang="scss">
.dryEyeTreatment {
  width: 100%;
  padding: 50px 0;
}
h3 {padding-bottom: 30px;}
.content {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 30px 0;
  .srnr {
    padding: 0 10px;
    border-right: 1px solid #8f8e8e;
    h4 {padding-bottom: 20px}
    ul {display: flex; align-items: center}
    li {
      width: 8em;
      padding: 5px 10px;
    }
    li:nth-child(1) {
      width: 20px;
      padding: 0 10px 0 0;
    }
  }
  .srnr:nth-child(2) {padding: 0 30px}
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
.isw90 {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: auto;
}
</style>
